<!--
 * @Author: LazyQ
 * @Date: 2020-11-24 14:56:55
 * @LastEditTime: 2020-11-26 23:32:59
 * @LastEditors: LazyQ
-->
<style lang="less" scoped>
.exchange {
  width: 1216.25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .exchange-tip {
    min-width: 1200px;
    min-height: 48px;
    line-height: 26px;
    padding: 20px;
    background-color: #eaf1ff;
    color: #447df5;
    font-size: 16px;
    margin-top: 24px;
    border-radius: 4px;
  }
  .exchange-content {
    width: 1216.25px;
    height: 800px;
    margin-top: 16px;
    overflow-y: overlay;
  }
  .exchange-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .loading {
    width: 100%;
    font-size: 16px;
    text-align: center;
  }
}
</style>

<template>
  <basic-layout>
    <div class="exchange">
      <div class="exchange-tip">
        奖励兑换说明：
        <br>1、兑换比例：100金币=1元。
        <br>2、奖励提取政策：满2000金币（合20元）以上金额可以提取到支付宝账号，在兑换页面点击提取按钮，当周周末由平台进行统一打款。请务必保证您的支付宝账号可用。
      </div>
      <div class="exchange-content" ref="body" @scroll="needLoad&&scrollLoad()" :style="{height: listHeight}">
        <div ref="container" class="exchange-list">
          <exchange-production
          @exchangeClick="exchange"
          :commodity="item"
          :balance="balance"
          :account="alipay_account"
          v-for="item in listData"
          :key="item.commodity_id"
          ></exchange-production>
        </div>
      </div>
      <div class="loading" v-if="loading">
          <a-spin />
          加载中...
      </div>
    </div>
    <a-modal
      :visible="visible"
      :confirm-loading="confirmLoading"
      title="提示"
      ok-text="确认"
      cancel-text="取消"
      @cancel="visible=false"
      @ok="handleOk">
      <p>确认兑换要{{infoDetail}}吗？</p>
    </a-modal>
    <a-modal
      :visible="hintModa"
      title="提示"
      ok-text="确认"
      cancel-text="取消"
      @cancel="hintModa=false"
      @ok="hintModa=false">
      <p>当前金币不足兑换</p>
    </a-modal>
  </basic-layout>
</template>

<script>
import Lib from "@/lib";
import BasicLayout from "@/layouts/BasicLayout.vue";
import ExchangeProduction from "@/components/ExchangeProduction.vue";
import { fetchCommoditylist, fetchExchangeOrder } from '@/api/commodity.js';
import { fetchUserInfo } from "@/api/user.js"

export default {
  name: "exchange",
  components: {
    BasicLayout,
    ExchangeProduction,
  },
  data() {
    return {
      hintModa: false,
      visible: false,
      confirmLoading: false,
      /// 当前商品名称
      infoDetail: '',
      /// 当前商品id
      currentCommodityId: undefined,
      balance: 0,
      listData: [],
      alipay_account: '',
      listHeight: document.documentElement.clientHeight - 270 + 'px',
      page_size: '50',
      page: 1,
      loading: false,
      needLoad: true,
    };
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      this.userInfoData();
      this.getlist()
    },
    exchange: function (msg, id) {
      if (this.balance < 2000) {
        this.hintModa = true;
        return;
      }
      this.infoDetail = msg;
      this.currentCommodityId = id;
      this.visible = true;
    },
    handleOk(e) {
      this.getEchangeOrder()
    },
    /// 获取商品列表
    async getlist() {
      this.loading = true;
      try {
        let result = await fetchCommoditylist({
          page: this.page,
          page_size: this.page_size
        });  
        /// 更新数据
        this.loading = false;
        if (result.total == 0 || result.commodity_list.length < this.page_size ) {
          this.needLoad = false;
        }
        this.listData.push(...result.commodity_list);
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    /// 获取用户信息
    async userInfoData() {
      try {
        let result = await fetchUserInfo({
          member_id: this.userId,
          token: this.userToken, 
        });  
        /// 同步数据
        this.alipay_account = result.alipay_account;
        this.balance = result.balance;
      } catch (error) {
        console.log(error);
      }
    },
    /// 获取商品列表
    async getEchangeOrder() {
      this.confirmLoading = true;
      try {
        let result = await fetchExchangeOrder({
          member_id: this.userId,
          token: this.userToken, 
          commodity_id: this.currentCommodityId
        });  
        this.confirmLoading = false;
        this.visible = false;
        this.$message.success("兑换成功")
      } catch (error) {
        this.confirmLoading = false;
        this.visible = false;
        console.log(error);
      }
    },
    /// 滚动list操作
    scrollLoad() {      
      Lib.debounce(() => {
        if (this.judgeBom() && !this.loading) {
          this.page++;
          this.getlist();
        } 
      },this);
    },
    judgeBom() {
      const ContainerDom = this.$refs.container
      let documentHeight = ContainerDom.scrollHeight; // 文档高度
      let containerHeight = this.$refs.body.offsetHeight // 容器高度
      let scrollHeight = this.$refs.body.scrollTop; // 滚动条滚动的距离
      console.log
      return documentHeight - containerHeight <= scrollHeight + 5;
    },
  }
};
</script>
