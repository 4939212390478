<!--
 * @Author: LazyQ
 * @Date: 2020-11-26 21:58:54
 * @LastEditTime: 2020-11-26 23:12:25
 * @LastEditors: LazyQ
-->
<style lang="less" scoped>
.production {
  width: 227px;
  height: 190px;
  // padding: 15px 12px;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 8.125px 20px;
  .prod-wrapper-wx {
    width: 100%;
    padding: 0 21px;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .ico-wx {
      width: 50px;
      height: 40.75px;
    }
    .prod-content {
      margin-left: 20px;
      flex: 1;
      height: 76px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
      }
      .cnt {
        font-size: 12px;
        span {
          color: #447df5;
          font-weight: bold;
        }
      }
    }
  }
  .prod-wrapper-ali {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 13px;
    .ali-content {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 12px;
      p {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }
      img {
        width: 28px;
        height: 28px;
      }
    }
    .ali-tip {
      width: 192px;
      height: 20px;
      margin-top: 6px;
      background-image: url('../assets/images/ali_tips.png');
      background-position: left;
      background-repeat: no-repeat;
      background-size: contain;
    }
    .ali-detail {
      font-size: 14px;
      margin-top: 6px;
      max-width: calc(100% - 26px);
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .ali-cnt {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 6px;
      p {
        font-size: 14px;
        color: #333333;
        span {
          color: #447df5;
          font-weight: bold;
        }
      }
    }
  }
  .prod-exchange-btn {
    width: 203px;
    height: 42px;
    line-height: 42px;
    background: #447df5;
    text-align: center;
    color: #ffffff;
    border-radius: 4px;
    margin-top: 12px;
    font-size: 16px;
    font-weight: bold;
  }
}
</style>

<template>
  <div class="production">
    <!-- <div class="prod-wrapper-wx" v-if="type"> -->
      <!-- <img class="ico-wx" :src="commodity.commodity_icon" alt="" /> -->
      <!-- <div class="prod-content"> -->
        <!-- <p class="title">{{ commodity.commodity_name}}</p> -->
        <!-- <p class="cnt">金币: <span>{{ commodity.commodity_price }}</span></p> -->
        <!-- <p class="cnt">剩余: 2800个</p> -->
      <!-- </div> -->
    <!-- </div> -->
    <div class="prod-wrapper-ali">
      <div class="ali-content">
        <p v-if="account || account.length>0">{{ commodity.commodity_name}}：{{account}}</p>
        <p v-else>未设置支付宝账号，请点击前往设置</p>
        <img :src="commodity.commodity_icon" alt="" />
      </div>
      <div class="ali-detail">{{ commodity.commodity_desc }}</div>
      <!-- <div class="ali-tip" /> -->
      <div class="ali-cnt">
        <p>金币: <span>{{ commodity.commodity_price }}</span></p>
      </div>
      <div class="ali-cnt">
        <p>您目前拥有 <span>{{ balance }}</span> 金币</p>
      </div>
    </div>
    <div v-if="account || account.length>0" class="prod-exchange-btn" @click="exchangeClick(commodity.commodity_desc, commodity.commodity_id)">申请兑换</div>
    <div v-else class="prod-exchange-btn" @click="setPay">立即设置</div>
  </div>
</template>

<script>

export default {
  props: {
    type: {
      type: Boolean,
      default: true,
    },
    balance: {
      type: Number,
      default: 0,
    },
    account: "",
    commodity: {}
  },
  methods: {
    exchangeClick: function (des, ids) {
      this.$emit("exchangeClick", des, ids);
    },
    setPay() {
      this.$router.push({  name: "User", query: { index: 3 } });
    }
  },
};
</script>